import React, { useState, useEffect } from 'react'
import cowImg from '../../assets/cards/cowMissionImg.png'
import { NavLink } from 'react-router-dom';
import './MissionCards.css';
function MissionCards({title, description,image}) {
    const [value, setValue] = useState(50);

    const handleSliderChange = (e) => {
        setValue(parseInt(e.target.value, 10));
    };
    useEffect(() => {
        const slider = document.querySelector('.slider');
        if (slider) {
            slider.style.setProperty('--slider-value', `${value}%`);
        }
    }, [value]);
    return (
        <>
            <div className="card card__mission">
                <img src={image} className="card-img-top" alt="..." />
                <div className="card-body">
                    <h2 className='card-heading'>{title}</h2>
                    <p className="card-text">{description}</p>
                </div>

                <div className="card-body d-flex flex-column gap-2">
                    <div className="slider-container">
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={value}
                            onChange={handleSliderChange}
                            className="slider"
                            style={{
                                 background: `linear-gradient(to right, #FF8400 ${value}%, #ddd ${value}%)`
                            }}
                        />
                        <div className="slider-value" style={{
                            
                            left: `calc(${value}% - ${(value +20)/4.5}px)`, // Center the value above the thumb
                
                        }}><span>{value}% </span></div>
                    </div>
                    <div className="value-container d-flex flex-row">
                        <p className="value1">Mission: 10 Lakhs</p>
                        <p className="value2 ms-auto">Achieved : 5 Lakhs</p>
                    </div>
                </div>

                <div className="card-body">
                    <NavLink className='btn mission-donate-btn' to="/payment/payment_form">Donate</NavLink>
                </div>
            </div>
        </>
    )
}

export default MissionCards